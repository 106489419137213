// Variables

$brand-primary: #29ac46; // Green
$brand-color-2: #FF7A00; // Orange
$brand-color-3: #f8f8f8; // Light gray
$brand-color-4: #cccdce; // Dark gray

$text-color: black;
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$link-color: #3d63ba; // Blue

$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;
