.popup-container {
  display: inline;
}

.popup {
  height: 0;
  margin: 0 -1rem;
  opacity: 0;
  visibility: hidden;
  &.animated {
    @include transition(all .2s ease-in-out);
  }
  &.active {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

.popup-content {
  background-color: $brand-color-3;
  font-size: 80%;
  line-height: 1.5;
  padding: 1.2em 1.5em 1em;
  text-align: justify;
}
