@charset "utf-8";

@import "fundraise";

#backdrop-xs {
  background-image: url("/images/Bristol_J-Soc_header_small.jpg");
}

.lead {
  font-size: 1.25em;
}

.container-reading {
  h2 {
    font-size: 2.6em;
    margin-bottom: 1em;
  }
  ul {
    color: #4c4c4c;
    margin-bottom: 3em;
    text-align: left;
  }
  li {
    margin-bottom: 1.2em;
  }
}

.intro {
  margin-bottom: 1em;
  padding-bottom: 4em;
}

@media only screen and (min-width: 48em) {
  #backdrop {
    background-image: url("/images/Bristol_J-Soc_header_large.jpg");
  }
  .lead {
    font-size: 1.25em;
  }
  .container-reading {
    max-width: 40em;
  }
  .intro {
    margin-bottom: 0;
    padding-bottom: 1.5em;
  }
}

