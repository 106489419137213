// Layout

header {
  text-align: center;
}

#logo {
  height: auto;
  max-width: 100%;
}

#backdrop {
  &:before, &:after {
    border-bottom-color: $brand-color-3;
  }
}

#backdrop-xs {
  background-color: $brand-color-3;
  background-position: 50% 50%;
  background-size: cover;
  min-height: 13em;
  padding-bottom: 65%;
  position: relative;
  .overlay {
    background: #333;
    background: rgba(0,0,0,.5);
    color: white;
    bottom: 0;
    font-size: 1.75em;
    left: 0;
    padding: .4em .4em .8em;
    position: absolute;
    width: 100%;
  }
  h1 {
    font-size: 1em;
    font-weight: bold;
    margin: 0 0 .25em;
  }
  .lead {
    font-size: .6em;
    margin-bottom: 0;
  }
}

.container-reading {
  max-width: 45rem;
  text-align: center;
  h2 {
    font-weight: 300;
    margin: 4rem 0 3rem;
  }
}

.intro {
  background-color: $brand-color-3;
  margin: 0 -15px;
  padding: 4.5em 15px 8em;
  h2 {
    margin-top: 0;
  }
  p {
    color: #4c4c4c;
    margin: 0 1.5625rem 1.25rem;
  }
}

blockquote {
  border-left: 0;
  font-size: 1.125em;
  margin: 1rem 0 1.25rem;
  padding: 0;
  text-align: center;
  footer {
    color: inherit;
    font-size: 1em;
    font-style: italic;
    padding-top: .6em;
    &:before {
      content: '\2013 \00A0'; //endash nbsp
    }
  }
}


.media-left {
  display: block;
  padding-right: 0;
}

.media-right {
  padding: 1em 1.5em 3em;
}
